import { Displayable } from 'django-rest-react';


declare var __SITE_URL__: string;
declare var __DEBUG__: boolean;
declare var __SENTRY_USE_SENTRY__: boolean;
declare var __SENTRY_DSN__: string;
declare var __VERSION__: string;
declare var __THE_SENDING_MAIL__: string;
declare var __MAIL_CONTATTO__: string;
declare var __JEST_TESTING__: boolean;
declare var __DISCORD_INVITE_LINK__: string;

export const site_url: string = __SITE_URL__;
export const DEBUG = __DEBUG__;
export const SHOW_STORE_DEBUG = __JEST_TESTING__ ? false : DEBUG;
export const VERSION = __VERSION__;
export const PROJECT_NAME = "gascal";
export const THE_SENDING_MAIL = __THE_SENDING_MAIL__;
export const MAIL_CONTATTO = __MAIL_CONTATTO__;
export const DISCORD_INVITE_LINK = __DISCORD_INVITE_LINK__;
export const TESTING = __JEST_TESTING__;

export const sentry_config = {
  use_sentry: __SENTRY_USE_SENTRY__,
  dsn: __SENTRY_DSN__,
  release: VERSION,
}

export { default_date_options as date_options, default_date_options as short_date_options, DEFAULT_LOCALE } from 'django-rest-react';
export * from './permessi'

// @ts-ignore
import styles from '../../../../django/gascal/static/gascal/scss/colors.module.scss';

import { Sessione, Evento, Contributor, TunableParametersForm } from '../api/types'

export const themeColors = __JEST_TESTING__ ? { "wet-asphalt": "#000000" } : styles;


// Questo deve essere sincronizzato con problems/models.py:DECIMAL_PLACES
export const DECIMAL_PLACES = 0.0001;


export const login_url = (redir?: string) => {
  const trgt = !!redir ? redir : window.location.pathname + window.location.hash.replace("#", "%23");
  window.location.assign(`${window.location.origin}/accounts/login/?next=${trgt}`)
}

export {
  SCUOLA_TIPI_SCUOLA as TIPOLOGIE, SQUADRA_ANNI_SCOLASTICI as ANNI,
  EVENTO_TIPI_EVENTO as TIPI_EVENTO,
} from './auto-enums'



export const componenti = [
  {
    name: "capitano",
    display: "Capitano"
  },
  {
    name: "consegnatore",
    display: "Consegnatore"
  },
  {
    name: "p1",
    display: "Terzo componente"
  },
  {
    name: "p2",
    display: "Quarto componente"
  },
  {
    name: "p3",
    display: "Quinto componente"
  }
];

export const COMPONENTI_FORM = componenti.map(comp => {
  return [comp.name, `anno_${comp.name}`]
}).flat()



export const toast_style = {
  position: 'absolute',
  top: 0,
  right: 0,
}


export const RUOLI_EVENTO: Displayable[] = [
  { id: 'adm', display: "Amministratore" },
  { id: 'ins', display: "Inseritore" },
]
export type RuoliEvento = "adm" | "ins";


export const DEFAULT_PARAMS_STANDARD_MODEL: Omit<TunableParametersForm, "SCALING_BONUS_VELOCITA" | "SCALING_BONUS_MONTEPREMI"> = {
  PTI_TOLTI_ERRORE: 10,
  PTI_BASE_MONTEPREMI: 70,
  MAX_ERRORI_RAISE_MONTEPREMI: 3,
  ERRORE_PUNTEGGI_RAISE: 20,
  PUNTEGGIO_BASE_PROBLEMI: 30,
  BONUS_VELOCITA: 20,
}


// export const image_placeholder_url = "https://normalenews.sns.it/sites/default/files/2019-04/dsc_04482.jpg"
export const image_placeholder_url = "/static/gascal/images/profile-placeholder.jpg"


const fabio: Omit<Contributor, "id"> = {
  website: "https://fabiozoratti.it",
  image_src: "/static/gascal/images/fabio-zoratti2.jpg",
  name: "Fabio Zoratti"
}
const bassonio: Omit<Contributor, "id"> = {
  website: "https://uz.sns.it/~Ilgatto",
  image_src: "/static/gascal/images/bassonio.png",
  name: "Antonio Matteri",
}
const altonio: Omit<Contributor, "id"> = {
  website: null,
  image_src: "/static/gascal/images/altonio.jpg",
  name: "Antonio Lombardi",
}
const gimmy: Omit<Contributor, "id"> = {
  website: "https://uz.sns.it/~Gimmy",
  image_src: "/static/gascal/images/gimmy.jpg",
  name: "Giovanni Maria Tomaselli",
}
const batpov: Omit<Contributor, "id"> = {
  website: null,
  image_src: "/static/gascal/images/batpov.jpg",
  name: "Daniele Battesimo Provenzano",
}
const nicolo: Omit<Contributor, "id"> = {
  website: null,
  image_src: "/static/gascal/images/nicolo.jpeg",
  name: "Niccolò Porciani"
}
const tino: Omit<Contributor, "id"> = {
  website: "https://mbarbieri.it/",
  image_src: "/static/gascal/images/tino.jpg",
  name: "Martino Barbieri",
}
const lucam: Omit<Contributor, "id"> = {
  website: null,
  image_src: "/static/gascal/images/lucam.jpg",
  name: "Luca Milanese",
}
const gug: Omit<Contributor, "id"> = {
  website: null,
  image_src: "/static/gascal/images/gug.jpg",
  name: "Guglielmo Di Grazia",
}


export const developers = [
  fabio,
  bassonio,
  {
    website: "https://uz.sns.it/~arna",
    image_src: "/static/gascal/images/arna.jpg",
    name: "Luca Arnaboldi",
  },
  {
    website: "https://uz.sns.it/~marco_venuti",
    image_src: "/static/gascal/images/marco_venuti.jpeg",
    name: "Marco Venuti",
  },
  {
    website: "https://uz.sns.it/~alepiazza",
    image_src: "/static/gascal/images/ale_piazza.jpeg",
    name: "Alessandro Piazza",
  },
  {
    website: "https://uz.sns.it/~KizunaGenshou",
    image_src: "/static/gascal/images/bruno_bucciotti.png",
    name: "Bruno Bucciotti",
  },
]

export const direzione = [
  fabio, bassonio, altonio,
  gimmy, batpov, tino,
  lucam, gug,
]


export const displaySessione = (ses: Sessione, eventi: Evento[]) => {
  const ev = eventi.find(i => i.id == ses.evento);
  if (!ev) {
    return ses.nome;
  }
  return ev.titolo + " - " + ses.nome
}

const lipsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."


const example_sponsor = {
  name: "Sponsor",
  website: "https://example.com/",
  logo_url: "/static/sponsors/example.png",
  description: lipsum,
}


// Returns true if we need the real value, false if we need a dummy replacement
const dummy = () => {
  if (__JEST_TESTING__) return false;
  if (typeof document === 'undefined') return false;
  return true;
}

export const testing_or_dummy = (name: string) => dummy() ? document.getElementById(name)?.textContent || undefined : name
export const locandina_casio = testing_or_dummy("locandina-casio");

export const sponsors_2023 = [
  {
    ord: 0,
    name: "Casio",
    website: "https://www.casio-edu.it/",
    logo_url: testing_or_dummy("logo-casio"),
    description: "CASIO Educational è un progetto volto ad offrire ai docenti formazione e supporto per l’introduzione ed utilizzo della calcolatrice grafica nella didattica quotidiana. Oltre a tutta l'offerta formativa presente sul sito (webinar gratuiti, risorse didattiche, supporto di docenti esperti), Casio Educational è presente a fiere di settore, competizioni di matematica e molto altro ancora, sempre con l’obiettivo di promuovere strumenti e metodologie innovative per la didattica."
  },
  {
    ord: 1,
    name: "Brilliant.org",
    website: "https://brilliant.org/olifis",
    logo_url: testing_or_dummy("logo-brilliant"),
    description: "Brilliant is the best online tool for learning math, science, and computer science. With courses for ambitious learners of all ages, Brilliant's visual content and hands-on lessons help you learn STEM interactively and at your own pace. Sharpen your technical skills and learn to be a better thinker with Brilliant."
  },
  {
    ord: 2,
    name: "Associazione per l'Insegnamento della Fisica (AIF)",
    website: "https://aif.it",
    logo_url: testing_or_dummy("logo-aif-blu"),
    description: "Come recita l’Articolo 1 dello Statuto, “L’Associazione per l’Insegnamento della Fisica (A.I.F.) è un’associazione senza fini di lucro, che ha lo scopo di migliorare e rivalutare l’insegnamento della fisica e di contribuire ad elevare il livello della cultura scientifica in Italia. A tal fine stampa e diffonde pubblicazioni a carattere didattico e culturale, organizza attività di aggiornamento e di formazione insegnanti, convegni e congressi ed effettua tutte le iniziative atte a conseguire lo scopo suddetto.”",
  },
];
export const sponsors_2024 = [sponsors_2023[0], sponsors_2023[2]]
export const sponsors = sponsors_2024;


export const simil_header_style = {
  fontSize: '3.2em',
  textAlign: 'center' as 'center',
}


export const logo_aif_verde = testing_or_dummy("logo-aif-verde");


// Questo ci dice se la sessione è grossa o meno in funzione del numero di squadre.
// Alcune funzionalità vengono disabilitate, per esempio il grafico temporale
// che mangia troppa RAM.
export const semplificato = (sq: number) => sq > 30;


export const dataToAnnoScolastico = (date: Date) => {
  if (date.getMonth() > 7) {
    return date.getFullYear() + 1;
  }
  return date.getFullYear();
}
