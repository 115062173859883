import React, { useEffect, useRef, useState } from 'react'

import { nice_date_seconds } from '../utils'
import { get_time } from '../api'
import { Evento } from '../api/types'

interface IProps {
  evento: Evento
}



export const fetch_server_time = (setDifference: (val: number) => void) => {
  get_time().then(ans => {
    const browser_now = new Date();
    const server_now = new Date(ans);

    // Se l'orologio del browser non è sincronizzato (10s) con il server, spawna un errore.
    const difference = (server_now.getTime() - browser_now.getTime()) / 1000;
    setDifference(difference);
  });
}

export const refresh_date_factory = (setNow: ReturnType<typeof useState>[1], difference: number) => () =>
  setNow(() => {
    const browser_now = new Date()
    const server_now = new Date(browser_now.getTime() + difference * 1000);
    return server_now;
  })



const DisplayOrario = (props: IProps) => {
  const refresh = useRef<number | null>(null);
  const [now, setNow] = useState(new Date());
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    fetch_server_time(setDifference);
  }, [])

  // Importante che siano due effect separati, altrimenti in una race condition viene creato il refresh date
  // sempre con difference = 0
  useEffect(() => {
    const refresh_date = refresh_date_factory(setNow, difference);

    refresh.current = window.setInterval(refresh_date, 1000);

    return () => {
      if (refresh.current !== null) {
        clearInterval(refresh.current);
      }
    }
  }, [difference])

  return (
    <>
      <h4 className="text-center">Orario attuale: {nice_date_seconds(now)}</h4>
      {now.getTime() > (new Date(props.evento.fine)).getTime() &&
        <h3 className="text-center text-warning">{"L'evento è concluso!"}</h3>
      }
      {(Math.abs(difference) > 10) &&
        <>
          <h4 className="text-center text-warning">Attenzione! L'orario di questo computer non è sincronizzato con l'orario del server.</h4>
          <h4 className="text-center text-warning">Questo computer è {Math.abs(difference).toFixed(0)} secondi in {difference > 0 ? "ritardo" : "anticipo"}</h4>
        </>
      }
    </>
  )
}

export default DisplayOrario;
